<template>
    <ViewTitle title="客戶服務 | 保單查詢">
        <template v-slot:buttons>
            <div class="flex mode-button">
                <Button
                    buttonWord="磊山保單"
                    buttonStyle="grey"
                    :class="mode === '1' ? 'active' : ''"
                    @click.prevent="changeMode('1')"
                />
                <Button
                    buttonWord="客戶上傳保單"
                    buttonStyle="grey"
                    :class="mode === '2' ? 'active' : ''"
                    @click.prevent="changeMode('2')"
                />
            </div>
        </template>
    </ViewTitle>
    <TagMenu
        class="tag-menu"
        :options="[
            { label: '磊山保單', value: '1' },
            { label: '客戶上傳保單', value: '2' }
        ]"
        :mode="mode"
        @change-mode="changeMode"
    />
    <InsuranceFilter
        :isLoading="isTableLoading"
        :name="$route.params.name"
        :mode="mode"
        @show-data-table="showDataTable"
        v-model:filterPayloadProp="filterPayload"
        v-model:filterLogProp="filterLog"
    />
    <keep-alive>
        <InsuranceTable
            v-if="mode === '1'"
            :listData="listData[1]"
            :isLoading="isTableLoading"
            :eventList="checkInsServices"
            :filterLog="filterLog[1]"
            :payload="filterPayload"
            :remindBeforeDays="remindBeforeDays"
            :hasReminderError="hasRemindSettingError"
            :manCode="parseInt(filterPayload.manCode)"
            @getinsuranceid="showEventModal"
            @showsuppliersyncmodal="showSupplierSyncModal"
            @reload="showDataTable"
        />
        <InsuranceTable2
            v-else
            :listData="listData[2]"
            :isLoading="isTableLoading"
            :filterLog="filterLog[2]"
            :payload="filterPayload"
            @reload="showDataTable"
        />
    </keep-alive>
    <component
        :is="currentModal"
        :isLoading="isTableLoading"
        v-model="isShowEventModal"
        :insId="insId"
        :manCode="this.filterPayload.manCode"
        @update-ins-services="updateInsServices"
        page="insurance"
        :insNo="insId"
        :supplier="supplier"
        :supplierCode="supplierCode"
        :customerId="customerId"
        :customerName="customerName"
        :dataStatus="dataStatus"
    >
    </component>
</template>

<script>
import _ from 'lodash'
import Button from '@/components/Button.vue'
import TagMenu from '@/components/TagMenu.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import InsuranceFilter from '@/containers/tia/InsuranceFilter.vue'
import InsuranceTable from '@/containers/tia/InsuranceTable.vue'
import InsuranceTable2 from '@/containers/tia/InsuranceTable2.vue'
import InsuranceNoticeModal from '@/containers/tia/InsuranceNoticeModal.vue'
import SupplierSyncModal from '@/containers/tia/SupplierSyncModal.vue'
import {
    getTiaInsuranceListAPI,
    getTiaInsuranceSettingAPI,
    getCTWInsuranceListAPI
} from '@/assets/javascripts/api'

export default {
    name: 'Insurance',
    components: {
        TagMenu,
        ViewTitle,
        InsuranceFilter,
        InsuranceTable,
        InsuranceTable2,
        InsuranceNoticeModal,
        SupplierSyncModal,
        Button
    },
    provide() {
        return {
            showDataTableProvide: this.showDataTable
        }
    },
    methods: {
        showDataTable: async function () {
            let selectedAccounts = this.filterPayload.manCodes.length
            if (selectedAccounts <= 0) return
            let isSkipLoading = this.$route.params.id ? true : false
            const payload = _.cloneDeep(this.filterPayload)
            try {
                if (!isSkipLoading) this.isTableLoading = true
                this.$showLoading()
                if (this.filterPayload) await this.getRemindBeforeDays()

                const permission = {
                    1: this.pageInfo,
                    2: 'skip'
                }[this.mode]

                const method = {
                    1: getTiaInsuranceListAPI,
                    2: getCTWInsuranceListAPI
                }[this.mode]

                const res = await this.$getResponse(payload, permission, method)

                this.listData[this.mode] =
                    res.data.insurances || res.data.insurance
            } catch (error) {
                this.listData[this.mode] = []
                console.error(error)
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        showEventModal: function (currentRow) {
            this.insId = currentRow.mainCode
            this.filterPayload.manCode = currentRow.manCode
            this.currentModal = 'InsuranceNoticeModal'
            this.isShowEventModal = true
        },
        showSupplierSyncModal: function (val) {
            const insurance = this.listData[this.mode].find(
                (item) => item.mainCode === val
            )
            this.insId = insurance.insNo
            this.supplierCode = insurance.supplierCode
            this.supplier = insurance.supplier
            this.customerId =
                insurance.insuredId === insurance.payerId
                    ? [insurance.insuredId]
                    : [insurance.insuredId, insurance.payerId]
            this.customerName = {
                insured: insurance.insured ?? null,
                payer: insurance.payer ?? null
            }
            this.dataStatus = insurance.fromSupplierDataStatus
            this.currentModal = 'SupplierSyncModal'
            this.isShowEventModal = true
        },
        updateInsServices: function (completed) {
            this.eventList[this.insId] =
                this.eventList[this.insId] > 0
                    ? this.eventList[this.insId] + completed.length
                    : completed.length
            let list = _.cloneDeep(this.checkInsServices)
            list = Object.assign(list, this.eventList)
            this.checkInsServices = list
        },
        getRemindBeforeDays: async function () {
            try {
                const res = await getTiaInsuranceSettingAPI(
                    this.$store.state.userInfo.code
                )
                this.remindBeforeDays = res.data.settings.remindBeforeDays
            } catch (error) {
                this.hasRemindSettingError = true
                console.log(error)
            }
        },
        changeMode: function (goto) {
            const current = this.$route.params.mode
            const mode = goto || (current === '1' ? '2' : '1')
            if (mode !== current) {
                this.$setGaEvent(`clickGoToMode${mode}`, 'click-Tag')
                this.$router.push({
                    path: `/tia/insurance/${mode}`
                })
            }
        }
    },
    computed: {
        mode: function () {
            return this.$route.params.mode
        }
    },
    data() {
        return {
            pageInfo: {
                apiAlias: 'list',
                apiName: '保單列表'
            },
            isTableLoading: false,
            isShowEventModal: false,
            isShowSupplierSyncModal: false,
            filterPayload: {
                manCode: ''
            },
            currentModal: null,
            listData: { 1: [], 2: [] },
            insId: null,
            supplierCode: null,
            supplier: null,
            customerId: null,
            customerName: {},
            selectedNotices: [],
            eventList: {},
            checkInsServices: {},
            dataStatus: null,
            filterLog: {},
            hasRemindSettingError: false,
            remindBeforeDays: 0
        }
    }
}
</script>

<style lang="scss" scoped>
.mode-button {
    margin-bottom: -35px;
    .button {
        background-color: $forth-white;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-right: 0px;
        &.active {
            position: relative;
            background-color: $primary-white;
            border-color: $primary-white;
            box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
            cursor: auto;
            &:last-of-type {
                box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.3);
            }
        }
        &:not(.active) {
            :deep(.button-word) {
                opacity: 0.5;
            }
            &:hover {
                :deep(.button-word) {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        display: none;
    }
}

.tag-menu {
    display: none;
    @media screen and (max-width: 576px) {
        display: block;
    }
}
</style>
